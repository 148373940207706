import { format } from 'date-fns';
import React from 'react';
//* CUSTOM IMPORTS
import { MdOutlineAirplaneTicket } from 'react-icons/md';

import { IAccommodation } from '../../interfaces/dtos/air.list.dtos';
import { AccommodationCard } from '../AcommodationCard/AccommodationCard';


export const ListAccommodations = ({ originAccommodation }: { originAccommodation: IAccommodation[] }) => (

  <>
    {originAccommodation.length === 0 && (
      <div style={{ textAlign: 'center', width: '100%', marginTop: 24 }}>
        <MdOutlineAirplaneTicket size={60} color="#00000070" />

        <p
          style={{
            fontSize: 13,
            margin: 0,
            padding: 0,
          }}
        >
          Você não possuí viagens cadastradas
        </p>
      </div>
    )}
    {originAccommodation.length > 0 && (
      <AccommodationCard
        hotel={originAccommodation[0].hotel}
        address={originAccommodation[0].address}
        date={originAccommodation[0].checkin_date}
        data={originAccommodation.map((origin, index) =>
          origin
        )}
      />
    )}
  </>
);
