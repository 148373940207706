
import { ITicket } from '../../../interfaces/dtos/air.list.dtos';
import { TicketApi } from './TicketApi';

export const getTicketData = async (email: string): Promise<ITicket[]> => {
  const data = await TicketApi.getTicketData(email);
  return data;
}
export const getTicketDataBack = async (email: string): Promise<ITicket[]> => {
  const data = await TicketApi.getTicketDataBack(email);
  return data;
}

export const getAirportData = async (match: string): Promise<ITicket[]> => {
  const data = await TicketApi.getAirportData(match);
  return data;
}
