


import { ITicket } from '../../../interfaces/dtos/air.list.dtos';
import api from '../api';



const getTicketData = async (email: string): Promise<ITicket[]> => {
  const { data } = await api.get(`/tickets?email=${email}`);
  return data || [];
}
const getTicketDataBack = async (email: string): Promise<ITicket[]> => {
  const { data } = await api.get(`/back/tickets?email=${email}`);
  return data || [];
}
const getAirportData = async (match: string): Promise<ITicket[]> => {
  const { data } = await api.get(`/airport/?match=${match}`);
  return data || [];
}
export const TicketApi = {
  getTicketData,
  getTicketDataBack,
  getAirportData
}