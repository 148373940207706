import React from 'react';
import { Container, LeftHole, RightHole } from "./ticketCard.styles";

function TicketCardLoader() {
  return (
    <Container>
      <LeftHole />
      <RightHole />

      <LeftHole style={{ top: 250 }} />
      <RightHole style={{ top: 250 }} />
    </Container>
  );
}

export { TicketCardLoader };
