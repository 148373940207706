import React from 'react';

import { FaBed } from 'react-icons/fa';

//* CUSTOM IMPORTS
import { AccommodationCardProps } from './Accommodation.interfaces';
import {
  Container,
  TicketItemContainer,
  Title,
  DescriptionContainer,
  BigTitle,
  Info,
  Header,
  LeftHole,
  RightHole,
  Small,
  Description,
  AirplaneContainer,
  DescriptionA,
} from './AccommodationCard.styles';

const TicketItemSubTitle = ({ title, subTitle, disableBorder, site }: any) => (
  <DescriptionContainer
    style={!disableBorder ? { borderLeft: '1px solid #00000020' } : undefined}
  >
    <Small>{title}</Small>
    {subTitle === 'Acessar o site' && site ? (
      <DescriptionA href={site}>{subTitle}</DescriptionA>

    ) : (
      <Description>{subTitle}</Description>

    )}
  </DescriptionContainer>
);

const TicketItem = ({
  userName,
  isDashed = false,
  description,
  site,
  phone,
  checkin_time,
  checkin_date,
  checkout_time,
  checkout_date,
}: any) => {

  return (
    <TicketItemContainer isDashed={isDashed}>
      <RightHole />
      <LeftHole />

      <Title>{userName}</Title>

      <div
        style={{
          display: 'flex',
          marginTop: 8,
          justifyContent: 'space-between',
        }}
      >
        <TicketItemSubTitle
          title="Telefone do Hotel"
          subTitle={phone}
          disableBorder
        />


        <TicketItemSubTitle title="Site do Hotel" subTitle="Acessar o site" site={site} />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 24,
        }}
      >
        <div>
          <Info>
            <Description>Check-in</Description>
            <BigTitle>{checkin_time}</BigTitle>
            <Description>{checkin_date}</Description>
          </Info>
        </div>

        <AirplaneContainer>
          <p>---------------</p>
          <FaBed size={28} color="#811B39" />
        </AirplaneContainer>

        <div>
          <Info>
            <Description>Check-out</Description>
            <BigTitle>{checkout_time}</BigTitle>
            <Description>{checkout_date}</Description>
          </Info>
        </div>
      </div>

      <Description style={{ textAlign: 'left', marginTop: 24 }}>
        {description}
      </Description>
    </TicketItemContainer>
  );
};

function AccommodationCard({ date, data, hotel, address }: AccommodationCardProps) {
  return (
    <Container>
      <Header>
        <div>
          <Title>{hotel}</Title>
          <Description style={{ marginTop: "-0.75rem" }}>{address}</Description>
        </div>
        <Title>{date}</Title>
      </Header>

      {data.map((ticket, index) => (
        <TicketItem isDashed={index > 0} key={ticket.id} {...ticket} />
      ))}
    </Container>
  );
}

export { AccommodationCard };
