


import { ITicket } from '../../../interfaces/dtos/air.list.dtos';
import api from '../api';



const getAccommodationData = async (email: string): Promise<ITicket[]> => {
  const { data } = await api.get(`/accommodations?email=${email}`);
  return data || [];
}
export const AccommodationApi = {
  getAccommodationData,
}