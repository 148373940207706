import styled from "styled-components";


export const Header = styled.div`
  top:0; 
  padding:0; 
  margin:0;
  font: 'Lato', sans-serif;

`;

export const Title = styled.h2 `
  top:0; 
  padding:0; 
  margin:0;
  font-size: 18;
  font: 'Lato', sans-serif;

`;

