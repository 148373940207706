import styled from "styled-components";

export const Container = styled.div`
  background-color: #00000010;
  max-width: 450px;
  height: 400px;
  border-radius: 8px;
  margin: 24px auto;
  position: relative;
`;

export const Hole = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #f5f5f5;
  position: absolute;
  top: 40px;
`;

export const LeftHole = styled(Hole)`
  left: 0;
  transform: translate(-50%, -50%);
`;

export const RightHole = styled(Hole)`
  right: 0;
  transform: translate(50%, -50%);
`;
