

//* CUSTOM IMPORTS
import { MdOutlineAirplaneTicket } from 'react-icons/md';

import { ITicket } from '../../interfaces/dtos/air.list.dtos';
import { formatTicket } from '../../utils/formatTicket';
import { TicketCard } from '../TicketCard';

export const ListTicketsBack = ({ originTicket }: { originTicket: ITicket[] }) => {
  // const [airportData, setAirportData] = useState<any>([]);
  // const [airportDataBack, setAirportDataBack] = useState<any>([]);



  // const loadTickets = useCallback(async () => {
  //   if (originTicket[0]) {
  //     setAirportData(originTicket[0].departure_airport_code)
  //     setAirportDataBack(originTicket[0].arrival_airport_code)

  //     const dataAirport: any = await getAirportData(originTicket[0].departure_airport_code);
  //     const dataAirportBack: any = await getAirportData(originTicket[0].arrival_airport_code);
  //     originTicket[0].departure_airport_code = dataAirport[0].iata
  //     originTicket[0].arrival_airport_code = dataAirportBack[0].iata

  //   }
  // }, [originTicket]);

  // useEffect(() => {
  //   loadTickets()

  // }, [loadTickets])
  return (

    <>
      {originTicket.length === 0 && (
        <div style={{ textAlign: 'center', width: '100%', marginTop: 24 }}>
          <MdOutlineAirplaneTicket size={60} color="#00000070" />

          <p
            style={{
              fontSize: 13,
              margin: 0,
              padding: 0,
            }}
          >
            Você não possui viagens cadastradas
          </p>
        </div>
      )}
      {originTicket.length > 0 && (
        <TicketCard
          ticketType={originTicket[0].ticket_type}
          company={originTicket[0].air_company}
          transfer={originTicket[0].transfer}
          date={originTicket[0].departure_date}
          data={originTicket.map((origin, index) =>
            formatTicket(origin, index, originTicket.length > 0)
          )}
        />
      )}
    </>
  );
}
