import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { Home } from '../pages/Tickets/Tickets.screen';

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}
