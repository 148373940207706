//* CUSTOM IMPORTS
import React from 'react';

import { Header } from './homeHeader.styles';

export const HomeHeader = () => {
  return (
    <>
      <Header>
      </Header>
    </>
  );
};
