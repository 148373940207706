import styled from "styled-components";

interface TicketItemContainerProps {
  isDashed?: boolean;
}

export const Container = styled.div`
  background-color: #F4F4F4;
  max-width: 450px;
  box-shadow: 0 3px 6px #00000030;
  border-radius: 8px;
  margin: 24px auto;
`;

export const AirplaneContainer = styled.div`
  position: relative;
  flex: 1;
  color: #811B39;
  p {
    top: -0.75rem;

    position: absolute;
  }

  &::before {
    position: absolute;
    left: -24px;
    content: "";
    width: 40%;
    left: 50%;
    transform: translateX(-100%);
    top: 11px;
    height: 1px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  padding-bottom: 0;
`;

export const TicketItemContainer = styled.div<TicketItemContainerProps>`
  text-align: center;
  position: relative;
  border-top: 1px solid #00000030;
  border-top-style: ${(props) => (props.isDashed ? "dashed" : "solid")};
`;

export const Hole = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #ffff;
`;

export const LeftHole = styled(Hole)`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  box-shadow: inset -3px 0px 6px #00000020;

  &::before {
    border-radius: 4px;
    position: absolute;
    content: "";
    background-color: #FFFF;
    height: 24px;
    width: 24px;
    left: 0;
    transform: translateX(-50%);
  }
`;

export const RightHole = styled(Hole)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  box-shadow: inset 3px 0px 6px #00000020;

  &::before {
    border-radius: 4px;
    position: absolute;
    content: "";
    background-color: #ffff;
    height: 24px;
    width: 24px;
    left: 0;
    transform: translateX(50%);
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  font: 'Lato', sans-serif;

`;

export const DescriptionContainer = styled.div`
  flex: 1;
  text-align: center;
`;

export const Description = styled.p`
  font-size: 16px;
  color: #000000bf;
  -webkit-font-smoothing: antialiased;
  font: 'Lato', sans-serif;
`;

export const DescriptionA = styled.a`
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font: 'Lato', sans-serif;

`;

export const Small = styled.p`
  font-size: 10px;
  color: #000000bf;
`;


export const BigTitle = styled.h2`
  font-weight: 24px;
  font-weight: bold;
  padding: 24px;
  padding-bottom: 0;
  padding-top: 0;
  -webkit-font-smoothing: antialiased;
  font: 'Lato', sans-serif;
`;

export const Info = styled.div`
  text-align: center;
`;
