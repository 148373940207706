import React, { useCallback, useEffect, useState } from 'react';

//* CUSTOM IMPORTS
import { useLocation } from 'react-router-dom';

import { HomeHeader } from '../../components/HomeHeader/homeHeader';
import { ListTickets } from '../../components/ListTickets/listTickets';
import { Button, HeaderMenu } from '../../components/Tabs/tabs.styles';
import { TicketCardLoader } from '../../components/TicketCard/TicketCardLoader/TicketCardLoader';
import { ListAccommodations } from '../../components/ListAccommodations/ListAccommodations';
import { getTicketData, getTicketDataBack, getAirportData } from '../../services/api/Ticket/TicketService';
import { getAccommodationData } from '../../services/api/Accommodation/AccommodationService';
import { ListTicketsBack } from '../../components/ListTicketsBack/listTicketsBack';




export const Home = () => {
  const { search } = useLocation();

  const [selected, setSelected] = useState(0);
  const [originTicket, setOriginTicket] = useState<any>([]);
  const [originAccommodation, setOriginAccommodation] = useState<any>([]);
  const [destinyTicket, setDestinyTicket] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState(true);



  const loadTickets = useCallback(async () => {
    try {
      const dataAir = await getTicketData(search.split('?')[1]);
      const dataAirBack = await getTicketDataBack(search.split('?')[1]);
      const dataAccommodation = await getAccommodationData(search.split('?')[1]);
      setOriginTicket(dataAir);
      setDestinyTicket(dataAirBack);
      setOriginAccommodation(dataAccommodation);

      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  }, [search]);






  useEffect(() => {
    loadTickets();

  }, [loadTickets]);


  return (
    <div style={{ flex: 1, justifyContent: 'center', padding: 16 }}>
      <HomeHeader />
      <HeaderMenu>
        <Button
          id={selected === 0 ? 'selected' : ''}
          onClick={() => setSelected(0)}
        >
          IDA
        </Button>
        <Button
          id={selected === 1 ? 'selected' : ''}
          onClick={() => setSelected(1)}
        >
          VOLTA
        </Button>
        <Button
          id={selected === 2 ? 'selected' : ''}
          onClick={() => setSelected(2)}
        >
          MINHA HOSPEDAGEM
        </Button>
      </HeaderMenu>

      {isLoaded ? (
        <>
          {(() => {
            switch (selected) {
              case 0:
                return <ListTickets originTicket={originTicket} />;
              case 1:
                return <ListTicketsBack originTicket={destinyTicket} />;
              case 2:
                return <ListAccommodations originAccommodation={originAccommodation} />;
              default:
                return <div>Invalid selection</div>;
            }
          })()}
        </>
      ) : (
        <TicketCardLoader />
      )}

    </div>
  );
};
