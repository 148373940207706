import React from 'react';
import { IoIosAirplane } from 'react-icons/io';
import { MdKeyboardArrowDown } from 'react-icons/md';

//* CUSTOM IMPORTS
import { TicketCardProps, TicketData } from './ticketCard.interfaces';
import {
  Container,
  TicketItemContainer,
  Title,
  DescriptionContainer,
  BigTitle,
  Info,
  Header,
  LeftHole,
  RightHole,
  Small,
  Description,
  AirplaneContainer,
  Transfer,
  LeftHoleTransfer,
  RightHoleTransfer,
} from './ticketCard.styles';

const TicketItemSubTitle = ({ title, subTitle, disableBorder }: any) => (
  <DescriptionContainer
    style={!disableBorder ? { borderLeft: '1px solid #00000020' } : undefined}
  >
    <Small>{title}</Small>

    <Description>{subTitle}</Description>
  </DescriptionContainer>
);

const TicketItem = ({
  codeReservation,
  company,
  title,
  userName,
  destinyCity,
  originCity,
  isDashed = false,
  description,
  destinyCityDate,
  destinyCityHour,
  originCityDate,
  originCityHour,
  locator,
  fly_number,
  baggage_allowance,
  originCityCode,
  destinyCityCode,
}: TicketData) => {

  return (
    <TicketItemContainer isDashed={isDashed}>
      <RightHole />
      <LeftHole />

      <Title>{userName}</Title>

      <div
        style={{
          display: 'flex',
          marginTop: 8,
          justifyContent: 'space-between',
        }}
      >
        <TicketItemSubTitle
          title="Localizador"
          subTitle={locator}
          disableBorder
        />
        <TicketItemSubTitle title="Nº do voo" subTitle={fly_number} />
        <TicketItemSubTitle title="Franquia de Bagagem" subTitle={baggage_allowance} />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 24,
        }}
      >
        <div>
          <Info>
            <BigTitle>{originCityCode}</BigTitle>
            <Description>{originCity}</Description>
          </Info>
          <MdKeyboardArrowDown size={40} color="#00000050" />
          <Info>
            <BigTitle>{originCityHour}</BigTitle>
            <Description>{originCityDate}</Description>
          </Info>
        </div>

        <AirplaneContainer>
          <p>---------------</p>
          <IoIosAirplane size={28} color="#811B39" />
        </AirplaneContainer>

        <div>
          <Info>
            <BigTitle>{destinyCityCode}</BigTitle>
            <Description>{destinyCity}</Description>
          </Info>

          <MdKeyboardArrowDown size={40} color="#00000050" />

          <Info>
            <BigTitle>{destinyCityHour}</BigTitle>

            <Description>{destinyCityDate}</Description>
          </Info>
        </div>
      </div>

      <Description style={{ textAlign: 'left', marginTop: 24 }}>
        {description}
      </Description>
    </TicketItemContainer>
  );
};

function TicketCard({ date, data, ticketType, company, transfer }: TicketCardProps) {
  return (
    <Container>
      <Header>
        <div >
          <Title>{ticketType}</Title>
          <Description style={{ marginTop: "-0.75rem" }}>{company}</Description>
        </div>
        <Title style={{ marginTop: "-0.75rem" }}>{date}</Title>
      </Header>

      {data.map((ticket, index) => (
        <TicketItem isDashed={index > 0} key={ticket.id} {...ticket} />
      ))}
      <Transfer>
        <LeftHoleTransfer />
        <RightHoleTransfer />
        <Title>Transfer</Title>
        <Description>{transfer}</Description>
      </Transfer>
    </Container>
  );
}

export { TicketCard };
