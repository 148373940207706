import styled, { css } from 'styled-components';

export const Button = styled.button`
  padding: 10px;
  display: inline-block;
  margin-top: 3.2rem;
  border: none;
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
  background-color: #ffff;
  opacity: 75%;

${props => props.id === 'selected' && css`
  //@ts-ignore
  border-bottom: solid 2px #811B39
    `};

`;

export const HeaderMenu = styled.div`
justify - content: space - between;
border - bottom: solid;
border - width: 1px;
border - color: #0000001a;
`;
