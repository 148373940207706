

import { TicketData } from '../components/TicketCard';
import { ITicket } from '../interfaces/dtos/air.list.dtos';


export const formatTicket = (
  ticket: ITicket,
  index: number,
  hasMore: boolean,
  airportData?: string,
  airportDataBack?: string
): TicketData => {
  const {
    air_company,
    ticket_number,
    arrival_time,
    id,
    arrival_airport_code,
    departure_time,
    departure_airport_code,
    arrival_city,
    arrival_date,
    departure_city,
    departure_date,
    baggage_allowance,
    fly_number,
    locator,
    // ticket_type,

    user_name: userName,
  } = ticket;


  return {
    id,
    userName,
    title: `VOO ${fly_number}`,

    company: air_company,
    codeReservation: ticket_number,

    originCity: departure_city || '',
    originCityCode: departure_airport_code,
    originAirport: airportData || '',
    originCityHour: departure_time || '',
    originCityDate: departure_date || '',

    destinyCity: arrival_city || '',
    destinyCityCode: arrival_airport_code,
    destinyAirport: airportDataBack || '',
    destinyCityHour: arrival_time || '',
    destinyCityDate: arrival_date || '',
    locator,
    fly_number,
    baggage_allowance,
  };
};
